.chat-app-window .user-chats {
    background-color: #f2f0f7;
    padding: 1rem;
    position: relative;
    height: calc(100% - 65px);
}

.chat-app-window .user-chats .avatar img {
    border: 2px solid #fff;
}

.chat-app-window .active-chat {
    height: inherit;
}

.chat-app-window .active-chat .chat-header {
    display: flex;
    justify-content: space-between;
    height: 65px;
    background-color: #fff;
    padding: 0 1rem;
    border-bottom: 1px solid #ebe9f1;
}

.chat-app-window .chats .chat-avatar {
    float: right;
}

.chat-app-window .chats .chat-body {
    display: block;
    margin: 10px 30px 0 0;
    overflow: hidden;
}

.chat-app-window .chats .chat-body .chat-content {
    float: right;
    padding: 0.7rem 1rem;
    margin: 0 1rem 10px 0;
    clear: both;
    color: #fff;
    background-image: linear-gradient(80deg, #7367f0, #9e95f5);
    border-radius: 0.357rem;
    box-shadow: 0 4px 8px 0 rgba(34, 41, 47, 0.12);
    max-width: 75%;
}

.chat-app-window .chats .chat-body .chat-content p {
    margin: 0;
}

.chat-app-window .chats .chat-left .chat-avatar {
    float: left;
}

.chat-app-window .chats .chat-left .chat-body .chat-content {
    float: left;
    margin: 0 0 10px 1rem;
    color: #6e6b7b;
    background: none;
    background-color: white;
}

.chat-app-window .chat-app-form {
    height: 65px;
    padding: 0 1rem;
    background-color: #fff;
    display: flex;
    align-items: center;
    border-top: 1px solid #ebe9f1;
}

.chat-widget .card-header {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
}

.chat-widget .chat-app-window .user-chats {
    background-color: #f8f8f8;
    height: 390px;
}

.chat-widget .chat-app-window .chat-app-form {
    border-top: 0;
    border-bottom-left-radius: 0.357rem;
    border-bottom-right-radius: 0.357rem;
    height: 56px;
}

.chat-widget .chat-app-window .chat-app-form .input-group-text,
.chat-widget .chat-app-window .chat-app-form .message {
    border: 0;
    padding-left: 0;
}

.chat-widget .chat-app-window .chat-app-form .input-group:not(.bootstrap-touchspin):focus-within {
    box-shadow: none;
}
.chat-app-window .chats .chat-body .chat-fix-message {
    display: none;
    cursor: pointer;
    margin-left: 10px;
    float: left;
    margin-top: 5px;
    color: black;
}
.chat-app-window .chats .chat-body .chat-fix-message:hover {
    display: block;
}
.chat-app-window .chats .chat-body:hover .chat-fix-message {
    display: block;
}
.images-bar {
    bottom: 0;
    position: absolute;
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    /* background-color: #22292f49; */
}

.images-bar img {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    margin-left: 5px;
    margin-right: 5px;
}
.images-bar svg:nth-child(1) {
    position: absolute;
    margin-left: 77px;
    cursor: pointer;
}
.images-bar svg:nth-child(2) {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    margin-left: 5px;
    margin-right: 5px;
}
.chat-right .text-file {
    color: white;
}
