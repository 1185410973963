#template-customizer {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 99999999;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 400px;
    -webkit-box-shadow: 0px 5px 30px 0px rgba(47, 43, 61, 0.18);
    box-shadow: 0px 5px 30px 0px rgba(47, 43, 61, 0.18);
    -webkit-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
    -webkit-transform: translateX(420px);
    -ms-transform: translateX(420px);
    transform: translateX(420px);
    background: #fff;
}
#template-customizer.template-customizer-open {
    -webkit-transition-delay: 0.1s;
    -o-transition-delay: 0.1s;
    transition-delay: 0.1s;
    -webkit-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
}
#template-customizer .chat-app-window .user-chats.short {
    height: calc(100vh - 260px);
}
#template-customizer .chat-application .chat-app-window .start-chat-area {
    height: calc(100vh - 125px);
}
@media (max-width: 1200px) {
    #template-customizer {
        display: none;
        visibility: hidden !important;
    }
}
@media (max-width: 575.98px) {
    #template-customizer {
        width: 300px;
        -webkit-transform: translateX(320px);
        -ms-transform: translateX(320px);
        transform: translateX(320px);
    }
}
.layout-menu-100vh #template-customizer {
    height: 100vh;
}
[dir='rtl'] #template-customizer {
    right: auto;
    left: 0;
    -webkit-transform: translateX(-420px);
    -ms-transform: translateX(-420px);
    transform: translateX(-420px);
}
